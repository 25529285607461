import React, { useState } from 'react';
import { FileField, FileInput, useInput, useNotify, Button } from 'react-admin';
import Papa from 'papaparse';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { format } from 'date-fns';
import { validateCSVContent } from '../../../utils/validateCSVContent';

const mapKeyToLabel = (key) => {
  switch (key) {
    case 'from':
      return 'Od';
    case 'to':
      return 'Do';
    case 'code':
      return 'Kod';
    default:
      return key;
  }
};

const mapLabelToKey = (label) => {
  switch (label) {
    case 'Od':
      return 'from';
    case 'Do':
      return 'to';
    case 'Kod':
      return 'code';
    default:
      return label;
  }
};

const CsvFileInput = () => {
  const notify = useNotify();
  const { input: fileInput } = useInput({ source: 'doorCodesFile' });
  const { input } = useInput({ source: 'doorCodes' });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleFileChange = (file) => {
    if (!file) {
      input.onChange(null); // Update the form value
      return;
    }

    const allowedTypes = ['text/csv'];

    if (!allowedTypes.includes(file.type)) {
      fileInput.onChange(null); // Update the form value
      notify('Only .csv files are allowed.', { type: 'warning' });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;

      const validationError = validateCSVContent(content);

      if (validationError) {
        fileInput.onChange(null); // Update the form value
        notify(validationError, { type: 'warning' });
        return;
      }

      Papa.parse(content, {
        delimiter: ';',
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => mapLabelToKey(header),
        complete: (results) => {
          input.onChange(results.data); // Update the form value
          setIsUpdated(true);
          notify('Plik załadowany poprawnie.', { type: 'info' });
        },
      });
    };

    reader.onerror = () => {
      notify('Error reading the file.', { type: 'error' });
    };

    reader.readAsText(file);
  };

  const doorCodes = input.value;

  return (
    <>
      <FileInput
        source="doorCodesFile"
        label="Plik z kodami do drzwi"
        accept=".csv"
        onChange={handleFileChange}
      >
        <FileField source="src" title="title" />
      </FileInput>
      {doorCodes && doorCodes.length > 0 && (
        <Button
          label={
            isUpdated
              ? 'Zobacz nowe kody do drzwi'
              : 'Zobacz zapisane kody do drzwi'
          }
          onClick={() => setPreviewOpen(true)}
          startIcon={<VisibilityIcon />}
        />
      )}

      {/* Modal for preview */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Kody do drzwi</DialogTitle>
        <DialogContent>
          {doorCodes.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(doorCodes[0]).map((key) => (
                    <TableCell key={key}>{mapKeyToLabel(key)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {doorCodes.map((row, index) => (
                  <TableRow key={index}>
                    {Object.values(row).map((value, idx) => {
                      let formattedValue = value;

                      if (idx === 0 || idx === 1) {
                        formattedValue = format(new Date(value), 'yyyy-MM-dd');
                      }

                      return <TableCell key={idx}>{formattedValue}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p>Brak danych</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button label="Zamknij" onClick={() => setPreviewOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CsvFileInput;
