/* eslint-disable no-plusplus */
export const validateCSVContent = (content) => {
  const rows = content.split('\n').filter((row) => row.trim() !== ''); // Remove empty rows

  // Check if the first row is the header
  const header = rows[0].trim();
  if (header !== 'Od;Do;Kod') {
    return 'Invalid CSV header. Expected: "Od;Do;Kod"';
  }

  // Validate the rest of the rows
  const rowPattern = /^\d{4}-\d{2}-\d{2};\d{4}-\d{2}-\d{2};\*\d{4}\*$/; // Pattern for each row
  for (let i = 1; i < rows.length; i++) {
    if (!rowPattern.test(rows[i].trim())) {
      return `Invalid format in row ${i + 1}: "${rows[i]}"`;
    }
  }

  return null; // No errors
};
